import React from 'react';
import cn from 'classnames';
import TextRenderer from '@components/TextRenderer';
import Button from '@components/Button';
import UICirclesAnimation from '@components/UICirclesAnimation';
import { GridSmall } from '@components/UICirclesAnimation/BackgroundElements';
import siteInfo from '@content/default.json';
import HomepageAnimated from '@components/Illustration/illustrations/HomepageAnimated';
import DynamicClientLogoMarquee from '@components/Marquee/DynamicClientLogoMarquee';

import * as styles from './HomepageHeroAnimated.module.css';
import * as textStyles from '@styles/textStyles.module.css';

export const HomepageHeroAnimated = ({
  heading,
  subline,
  cta = 'Book a demo',
  clientLogos = [],
  pillars = [],
}) => {
  const style = {
    '--column-span': 12,
    '--column-span-medium': 4,
    '--column-span-large': 4,
  };

  return (
    <div className={cn(styles.hero)}>
      <div className={cn(styles.top)}>
        <div className={cn(styles.foreground)}>
          <div className={cn(styles.wrapper)}>
            <div className={cn(styles.wrapperInner)}>
              <div className={cn(styles.heroText)}>
                {heading && (
                  <TextRenderer
                    className={cn(textStyles.headlineXL, styles.heading)}
                    text={heading}
                    as={'h1'}
                  />
                )}
                {subline && (
                  <TextRenderer
                    className={cn(textStyles.headlineS, styles.subline)}
                    text={subline}
                    as={'h1'}
                  />
                )}
              </div>
              {cta && (
                <div className={cn(styles.cta)}>
                  <Button
                    size="large"
                    href={siteInfo.bookDemoURL}
                    trackingScope={'Hero'}
                  >
                    {cta}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={cn(styles.background)}>
          <div className={cn(styles.grid)}>
            <GridSmall />
          </div>
          <div className={cn(styles.illustration)}>
            <HomepageAnimated />
          </div>
          <div className={cn(styles.topVignette)}></div>
          <div className={cn(styles.leftVignette)}></div>
          <div className={cn(styles.bottomVignette)}></div>
          <div className={cn(styles.rightVignette)}></div>
        </div>
      </div>

      {clientLogos && clientLogos.length > 0 && (
        <DynamicClientLogoMarquee clientLogos={clientLogos} />
      )}
      <div className={cn(styles.bottom)}>
        {pillars && pillars.length > 0 && (
          <div className={cn(styles.pillars)}>
            {pillars.map((pillar, index) => (
              <div key={index} className={cn(styles.pillar)} style={style}>
                <TextRenderer
                  className={cn(textStyles.headlineS, styles.pillarHeading)}
                  text={pillar.heading}
                  as={'h1'}
                />
                <TextRenderer
                  className={cn(textStyles.bodyM, styles.pillarText)}
                  text={pillar.text}
                  as={'p'}
                />
              </div>
            ))}
          </div>
        )}
        <UICirclesAnimation />
      </div>
    </div>
  );
};
