import cn from 'classnames';

import AnimateIn from '@components/AnimateIn';
import Button from '@components/Button';
import TextRenderer from '@components/TextRenderer';

import Form from './Form';
import ResourceMockup from './ResourceMockup';

import measureStyles from '@styles/utilities/measures.module.css';
import textStyles from '@styles/textStyles.module.css';
import * as styles from './CampaignHeroResource.module.css';
import { submitRoute } from '@util/routes';

export const CampaignHeroWithResource = ({
  name,
  eyebrow,
  heading,
  text,
  resource,
}) => {
  const handleFormSubmit = async ({ data, name }, { reject, resolve }) => {
    try {
      const res = await fetch(submitRoute(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          formName: 'campaignResource',
          campaignName: name,
          ...data,
        }),
      });

      if (res && res.status === 202) {
        resolve();
      } else {
        reject();
      }
    } catch (e) {
      reject();
    }
  };

  const HeadlineTag = 'h1';
  const media = <ResourceMockup resource={resource} />;

  const { ctaInstruction, ctaLabel, resourceUrl, withForm } = resource;

  return (
    <div className={styles.root}>
      <div className={styles.text}>
        <div>
          {eyebrow && <p className={cn(textStyles.eyebrow)}>{eyebrow}</p>}
          <TextRenderer
            text={heading}
            as={HeadlineTag}
            className={cn(styles.headline, textStyles.headlineXL)}
          />
        </div>

        <AnimateIn>
          <div className={cn(styles.callToActionCard)}>
            {text && (
              <TextRenderer
                className={cn(textStyles.bodyL, measureStyles.medium)}
                text={text}
              />
            )}

            {!withForm ? (
              <div className={styles.callToActionNoForm}>
                <div className={cn(styles.callToActionInstructions)}>
                  {ctaInstruction}
                </div>
                <Button size="large" href={resourceUrl} trackingScope="">
                  {ctaLabel}
                </Button>
              </div>
            ) : (
              <div className={styles.callToActionForm}>
                <div className={cn(styles.callToActionInstructions)}>
                  {ctaInstruction}
                </div>
                <div className={cn(styles.callToActionFormWrapper)}>
                  <Form
                    ctaLabel={ctaLabel}
                    name={name}
                    resourceUrl={resourceUrl}
                    saveData={handleFormSubmit}
                  />
                </div>
              </div>
            )}
          </div>
        </AnimateIn>
      </div>
      <figure className={styles.media}>{media}</figure>
    </div>
  );
};
